<template>
  <div class="logo-back">
    <div id="glitch-container">
      <img
        src="@/assets/hello_puffin.png"
        alt="Hello puffin"
        style="visibility: hidden; width: 100%;"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "GlitchBox",
  mounted() {
    let bg = document.getElementById("glitch-container");

    let count = 35;
    for (let i = 0; i < count; i++) {
      let glitchBox = document.createElement("div");
      glitchBox.className = "box";

      bg.appendChild(glitchBox);
    }

    let func = () => {
      let glitch = document.getElementsByClassName("box");

      for (let i = 0; i < glitch.length; i++) {
        glitch[i].style.left = Math.floor(Math.random() * 100) + "%";
        glitch[i].style.top = Math.floor(Math.random() * 100) + "%";
        glitch[i].style.width = Math.floor(Math.random() * 190) + "px";
        glitch[i].style.height = Math.floor(Math.random() * 40) + "px";
      }
    };

    function sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }

    let glitch = async () => {
      document.getElementById("glitch-container").style.visibility = "visible";
      for (let index = 0; index < 5; index++) {
        await sleep(75).then(func());
      }
      document.getElementById("glitch-container").style.visibility = "hidden";
    };

    let loopGlitch = async () => {
      await sleep(this.getRandomInteger(6, 13) * 1000);
      await glitch();
      loopGlitch();
    }

    loopGlitch();
  },
  methods: {
    getRandomInteger(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
  },
};
</script>

<style>
.logo-back {
  position: relative;
  width: 100%;
  background: url("../assets/hello_puffin.png");
  background-size: 100%;
}
#glitch-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.box {
  position: absolute;
  background-image: url("../assets/hello_puffin.png");
  background-color: white;
  background-attachment: fixed;
  background-size: 100%;
}
</style>
