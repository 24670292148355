<template>
  <div>
    <span class="page-header">Contact</span>
    <br>
    <span>contact<span style="font-family: 'Roboto Slab'">@</span>exoware.tech</span><br>
    <br>
  <typed-text text="Feel free to contact us. We will get back to you as soon as possible." />

    <div class="row" style="height: 75px"></div>
  </div>
</template>

<script>
import '@/components/TypedText.vue'
import TypedText from '@/components/TypedText.vue'
export default {
  name: 'Contact',
  components: {
    TypedText
  }
}
</script>
