"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    props: {
        text: String,
        customSpeed: Number,
    },
    data: function () {
        var _a;
        return {
            speed: (_a = this.customSpeed) !== null && _a !== void 0 ? _a : 50,
            iterations: 9,
            output: "",
            characters: "░▒▓",
        };
    },
    mounted: function () {
        this.printOut();
    },
    methods: {
        getRandomInteger: function (min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        randomCharacter: function (chars) {
            return chars[this.getRandomInteger(0, chars.length - 1)];
        },
        printOut: function () {
            var _this = this;
            if (this.output.length < this.text.length) {
                var index_1 = this.output.length;
                this.output += this.randomCharacter(this.characters);
                for (var iteration = 0; iteration < this.iterations; iteration++) {
                    setTimeout(function () { _this.output = _this.text.substring(0, index_1) + _this.randomCharacter(_this.characters); }, this.speed / (this.iterations - iteration));
                }
                setTimeout(function () { _this.output = _this.text.substring(0, index_1 + 1); }, this.speed);
                setTimeout(function () { _this.printOut(); }, this.speed);
            }
        },
    },
};
