<template>
  <div>
    <span class="page-header">Privacy policy</span><br>
    <p>Last updated: April 2, 2024</p>

    <!-- 1 -->
    <p class="paragraph-header">1. What information do we collect?</p>

    <p>We only collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us. The personal information that we collect depends on the context of your interactions with us.</p>

    <!-- 2 -->
    <p class="paragraph-header">2. How do we use your information?</p>

    <p>Your personal information will only be used for correspondence.</p>

    <!-- 3 -->
    <p class="paragraph-header">3. Will your information be shared with anyone?</p>

    <p>
        We may process or share your data that we hold based on the following legal basis:
        <ul>
            <li>Consent: We may process your data if you have given us specific consent to use your personal information for a specific purpose.</li>
            <li>Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</li>
            <li>Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</li>
        </ul>
    </p>

    <!-- 4 -->
    <p class="paragraph-header">4. Do we use cookies and other tracking technologies?</p>

    <p>No.</p>

    <!-- 5 -->
    <p class="paragraph-header">5. How long do we keep your information?</p>

    <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy. No purpose in this notice will require us keeping your personal information for longer than 2 years.</p>

    <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>

    <!-- 6 -->
    <p class="paragraph-header">6. How do we keep your information safe?</p>

    <p>We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information is at your own risk.</p>

    <!-- 7 -->
    <p class="paragraph-header">7. What are your privacy rights?</p>

    <p>In some regions (like the EEA and UK), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please use the contact details provided below. We will consider and act upon any request in accordance with applicable data protection laws.</p>

    <p>If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note however that this will not affect the lawfulness of the processing before its withdrawal, nor will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</p>

    <p>If you are a resident in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.</p>

    <p>If you are a resident in Switzerland, the contact details for the data protection authorities are available here: https://www.edoeb.admin.ch/edoeb/en/home.html.</p>

    <p>Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Website. To opt-out of interest-based advertising by advertisers on our Website visit http://www.aboutads.info/choices/.</p>

    <!-- 8 -->
    <p class="paragraph-header">8. Do California residents have specific privacy rights?</p>

    <p>California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</p>

    <p>If you are under 18 years of age, reside in California, and have a registered account with the Website, you have the right to request removal of unwanted data that you publicly post on the Website. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Website, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g. backups, etc.).</p>

    <!-- 9 -->
    <p class="paragraph-header">9. Do we make updates to this notice?</p>

    <p>We may update this privacy policy from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.</p>

    <!-- 10 -->
    <p class="paragraph-header">10. How can you contact us about this notice?</p>

    <p>If you have questions or comments about this notice, please email us at: contact<span style="font-family: 'Roboto Slab'">@</span>exoware.tech.</p>

    <!-- 11 -->
    <p class="paragraph-header">11. How can you review, update,or delete the data we collect from you?</p>

    <p style="margin-bottom: 0;">Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please email us at: contact@exoware.tech.</p>

    <div class="row" style="height: 75px"></div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  components: {
  }
}
</script>

<style scoped>
    ul{ 
        list-style: none;
    }
    ul li:before {
        content:"•    ";
        font-size:13pt; 
    }
</style>