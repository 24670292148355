"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var App_vue_1 = require("./App.vue");
var router_1 = require("./router");
// Import Bootstrap an BootstrapVue CSS files (order is important)
require("bootstrap/dist/css/bootstrap.css");
require("bootstrap-vue/dist/bootstrap-vue.css");
require("@/assets/style.css");
vue_1.default.config.productionTip = false;
new vue_1.default({
    router: router_1.default,
    render: function (h) { return h(App_vue_1.default); }
}).$mount('#app');
