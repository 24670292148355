<template>
  <div>
    <span class="page-header">Timeline</span>
    <br>
    <span>2020 Feb. - Exoware Tech B.V. is founded.</span><br>
    <span>2020 Feb. - Began researching and developing SaaS software.</span><br><br>

    <span>2022 Sep. - Achieved a fundamental technical breakthrough; <typed-text text="the missing piece for AI-powered solutions" :customSpeed="100" />.</span><br><br>

    <span>2023 Jul. - Our efforts culminated in the creation of an <typed-text text="undisclosed product" :customSpeed="50" />, set to revolutionize the digital landscape.</span><br><br>

    <span>2024 Mar. - Finalized <typed-text text="prototype" :customSpeed="150" /> of the undisclosed product.</span><br>
    <span>2024 Apr. - In talks with potential investment partners to launch the West's first <typed-text text="..." customSpeed="450" /> </span>

    <div class="row" style="height: 75px"></div>
  </div>
</template>

<script>
import TypedText from '@/components/TypedText.vue';

export default {
  name: 'Timeline',
  components: {
    TypedText,
  }
}
</script>
