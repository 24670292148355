"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var GlitchBox_vue_1 = require("@/components/GlitchBox.vue");
exports.default = {
    mounted: function () {
        // Disable dragging on logo.
        document.getElementById("homeLink").ondragstart = function (dragEvent) {
            dragEvent.preventDefault();
        };
    },
    components: {
        GlitchBox: GlitchBox_vue_1.default,
    },
};
