"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_router_1 = require("vue-router");
vue_1.default.use(vue_router_1.default);
var routes = [
    {
        path: '/',
        name: 'Home',
    },
    {
        path: '/cntct',
        name: 'Contact',
        component: function () { return Promise.resolve().then(function () { return require('../views/Contact.vue'); }); },
    },
    {
        path: '/tmln',
        name: 'Timeline',
        component: function () { return Promise.resolve().then(function () { return require('../views/Timeline.vue'); }); },
    },
    {
        path: '/trmsndcndtns',
        name: 'Terms and conditions',
        component: function () { return Promise.resolve().then(function () { return require('../views/TermsAndConditions.vue'); }); },
    },
    {
        path: '/prvcyplcy',
        name: 'Privacy policy',
        component: function () { return Promise.resolve().then(function () { return require('../views/PrivacyPolicy.vue'); }); },
    },
    {
        path: '/dsclmr',
        name: 'Disclaimer',
        component: function () { return Promise.resolve().then(function () { return require('../views/Disclaimer.vue'); }); },
    },
];
var router = new vue_router_1.default({
    mode: 'hash',
    base: process.env.BASE_URL,
    scrollBehavior: function () {
        return new Promise(function (resolve) {
            setTimeout(function () {
                resolve({ x: 0, y: 0, });
            }, 10);
        });
    },
    routes: routes
});
exports.default = router;
